import React, { Component } from "react";
import stackStore from "../images/stack-store.png";
import Footer from "./footer";

class Terms extends Component {
  render() {
    return (
      <div>
        <header className="store-header">
          <img src={stackStore} alt="Stack Logo" className="store-logo" />
          <div>T &amp; C</div>
        </header>

        <section id="privacy-policy">
          <h2>Privacy Policy</h2>
          <ol>
            <li>
              <strong>Information Collection:</strong> We collect personal
              information such as name, address, email, and phone number for
              order processing and customer communication.
            </li>
            <li>
              <strong>Data Usage:</strong> Information collected is used for
              order fulfillment, customer support, and to improve our services.
            </li>
            <li>
              <strong>Security Measures:</strong> We implement security measures
              to protect personal information and ensure its confidentiality.
            </li>
            <li>
              <strong>Third-Party Disclosure:</strong> We do not sell, trade, or
              share personal information with third parties without explicit
              consent.
            </li>
            <li>
              <strong>Cookies:</strong> Our website may use cookies to enhance
              user experience. Users can adjust browser settings to disable
              cookies if desired.
            </li>
            <li>
              <strong>Updates:</strong> Our privacy policy may be updated, and
              users will be notified of any changes.
            </li>
          </ol>
        </section>

        <section id="cancellation-policy">
          <h2>Cancellation Policy</h2>
          <ol>
            <li>
              <strong>Order Cancellation:</strong> Customers can cancel orders
              within a specified timeframe (set by the management) after placing
              the order by reaching the customer support, if the order is being
              cancelled at doorstep any fees paid before will not be refunded.
              Management has the right to change the cancellation policy at any
              time.
            </li>
            <li>
              <strong>Refund Eligibility:</strong>Approved refunds will be
              issued for cancelled orders if they meet the criteria outlined in
              our refund policy.
            </li>
            <li>
              <strong>Refund Processing:</strong> Approved refunds will be
              processed within a certain number of days (15 to 30 Working Days)
              after cancellation, and customers will be notified.
            </li>
            <li>
              <strong>Product Return:</strong> In order to become eligible to
              return product, you should be sending us an unboxing video of the
              order, which should clearly display the issue for the product
              return. In case of product returns this has to be clearly
              communicated with our customer support team, and has to be
              dispatched withing two days of the delivery. Replacement or
              refunds may initiated by our customer support executive within 15
              to 30 working days followed by examining the condition of returned
              goods, if the returned goods are spoiled or damaged or over-used
              by the customer then the refund cannot be made. When returning the
              same product again, it has to be done in the same day of delivery,
              unless the refund or replacement cannot be applicable.
            </li>
            <li>
              <strong>Cancellation Fee:</strong> Depending on the timing of
              cancellation, a cancellation fee may apply. This fee will be
              clearly communicated to the customer.
            </li>
            <li>
              <strong>Communication:</strong> Customers will be informed of the
              cancellation process and any applicable fees at the time of order
              placement.
            </li>
          </ol>
        </section>

        <section id="shipping-delivery-policy">
          <h2>Shipping and Delivery Policy</h2>
          <ol>
            <li>
              <strong>Order Processing Time:</strong> Orders will be processed
              within 5 days after payment confirmation.
            </li>
            <li>
              <strong>Shipping Duration:</strong> Our standard shipping time is
              up to 3 to 7 days. However, actual delivery times may vary based
              on factors such as product availability, destination, and delivery
              partner efficiency.
            </li>
            <li>
              <strong>Delivery Partners:</strong> We collaborate with trusted
              delivery partners to ensure timely and secure delivery of your
              orders.
            </li>
            <li>
              <strong>Tracking Information:</strong> Customers will receive
              tracking information once the order has been dispatched. This
              allows them to monitor the delivery status.
            </li>
            <li>
              <strong>Delivery Variability:</strong> Please note that delivery
              times may vary due to unforeseen circumstances, such as weather
              conditions, customs clearance, or other external factors beyond
              our control.
            </li>
            <li>
              <strong>Communication:</strong> Customers will be informed of any
              delays or changes in the estimated delivery time promptly. Our
              customer support team is available to address inquiries related to
              shipping and delivery.
            </li>
            <li>
              <strong>Delivery Charges:</strong> Any applicable delivery charges
              will be clearly communicated during the checkout process.
            </li>
            <li>
              <strong>International Shipping:</strong> For international orders,
              customers are responsible for any customs duties or taxes imposed
              by their respective countries.
            </li>
            <li>
              <strong>Incomplete or Incorrect Addresses:</strong> Customers are
              responsible for providing accurate and complete delivery
              addresses. Additional charges may apply for redelivery due to
              incorrect or incomplete addresses.
            </li>
            <li>
              <strong>Delivery Confirmation:</strong> Once the order is
              successfully delivered, customers will receive a confirmation
              email.
            </li>
          </ol>
        </section>

        <h1>Terms and Conditions</h1>

        <p>
          Thank you for choosing Stack Enterprises | SalesMart.In for your
          online shopping needs. We are committed to safeguarding your privacy.
          If you have any questions or concerns, please contact our customer
          support team. - You agree to comply with all applicable laws and
          regulations when using our website.
          <br />
          - You are responsible for maintaining the confidentiality of your
          account and password and for restricting access to your computer.
          <br />
          - We reserve the right to refuse service, terminate accounts, or
          cancel orders at our discretion.
          <br />
          - Prices, product availability, and terms are subject to change
          without notice.
          <br />
          - Unauthorized use of this website may give rise to a claim for
          damages and/or be a criminal offense.
          <br />
          - Your use of this website and any dispute arising out of such use is
          subject to the laws of [your country/state].
          <br />
        </p>

        <footer>
          <p>
            Thank you for choosing Stack Enterprises | SalesMart.In for your
            online shopping needs. We are committed to safeguarding your
            privacy. If you have any questions or concerns, please contact our
            customer support team.
          </p>
        </footer>
        <Footer />
      </div>
    );
  }
}

export default Terms;

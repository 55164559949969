import React, { Component } from "react";
import Product from "./Product";
import homeIcon from "../images/icon-home.png";
import CheckRoundIcon from "@rsuite/icons/CheckRound";
import stackStore from "../images/stack-store.png";

import {
  Affix,
  Button,
  Checkbox,
  Divider,
  Input,
  InputNumber,
  InputPicker,
  Loader,
  Message,
} from "rsuite";
import Cookies from "js-cookie";
import constants, { alertError, alertInfo, isValid } from "../constants";
import GLogin from "./GLogin";
import OTPInput from "react-otp-input";
import Checkout from "./Checkout";
import Footer from "./footer";
import CustomerData from "./widgets/CustomerData";

class CartView extends Component {
  state = {
    loginData: null,
    autoPay: false,
  };

  componentDidMount = () => {
    setTimeout(this.props.sync, 600);
  };

  /**GETTING CARTED PRODUCTS */
  getCartedProducts = (products, cart) => {
    const cartedProducts = cart.map((cartItem) => {
      const product = products.find(
        (product) => product.id === cartItem.product_id
      );

      // If the product is found, create a new object with additional cart-related information
      if (product) {
        return {
          ...product,
          qty: cartItem.qty,
          // Add other cart-related properties as needed
        };
      }

      return null;
    });

    // Filter out any null values (products not found)
    return cartedProducts.filter(Boolean);
  };

  render() {
    var {
      back,
      products,
      cart,
      addProduct,
      removeProduct,
      isInCart,
      productDrawer,
      uid,
      data,
      customerData,
    } = this.props;

    // var sale = data?.sale;
    // var customer = data?.customer;

    var cartedProducts = this.getCartedProducts(products, cart);

    var totalAmountWithFormat = new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "INR",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(
      parseFloat(data?.channel_rate) +
        parseFloat(data?.total_amount) +
        parseFloat(data?.cod_fee)
    );

    return (
      <div>
        <Affix>
          <header className="store-header">
            <img src={stackStore} alt="Stack Logo" className="store-logo" />
            <div>
              <button className="cart-button" onClick={back}>
                <img src={homeIcon} />
              </button>
            </div>
          </header>
        </Affix>
        {!uid || !data ? (
          <div className="loading">
            <Loader content="Loading cart.." />
          </div>
        ) : (
          <div>
            <div className="simple-text-banner">
              <Button appearance="link" onClick={back}>
                Back to home
              </Button>
              Your Cart{" "}
              {cart.length == 0 ? "is Empty" : `has ${cart.length} Items`}
            </div>
            {cartedProducts.map((product) => (
              <Product
                key={product.id}
                product={product}
                addProduct={addProduct}
                removeProduct={removeProduct}
                isInCart={isInCart}
                click={() => productDrawer(true, product)}
                miniLoading={this.props.miniLoading}
                mini={true}
              />
            ))}
            <br />
            <div className="cart-summary">
              <div className="tbl">
                <span>Weight</span>
                <span>
                  {data.total_weight}g
                  <sub> (Payable: {data.charged_weight}g)</sub>
                </span>
              </div>

              <div className="tbl">
                <span>Delivery Charge</span>
                <span>
                  {data.charged_weight > 0 ? `₹${data.channel_rate}` : "FREE"}
                </span>
              </div>

              {data.cod ? (
                <div className="tbl">
                  <span>Cash on Delivery Charge</span>
                  <span>₹{data.cod_fee}</span>
                </div>
              ) : (
                ""
              )}
              <div className="tbl">
                <span>Total Amount</span>

                <span>{totalAmountWithFormat}</span>
              </div>
              <br />
            </div>
            {!isValid(data.name, 5) ||
            !isValid(data.phone, 10) ||
            !isValid(data.address, 7) ||
            !isValid(data.postal_pin, 6) ? (
              <CustomerData
                customerData={this.props.customerData}
                data={this.props.data}
                setCustomerData={this.props.setCustomerData}
                miniLoading={this.props.miniLoading}
                sync={this.props.sync}
              />
            ) : (
              <div>
                <br />
                <Checkbox
                  disabled={this.props.miniLoading}
                  className="cod-checkbox"
                  checked={data.cod}
                  onChange={(type, bool) => {
                    this.props.setCod(type, bool);
                  }}
                >
                  {" "}
                  <span>
                    Need Cash On Delivery?
                    {data.cod_fee > 0 && (
                      <i>
                        <br />
                        <small>
                          ഓൺലൈൻ പേയ്മെന്റ് വഴി {data.cod_fee} രൂപ
                          ലാഭിക്കാവുന്നതാണ്
                        </small>
                      </i>
                    )}
                  </span>
                </Checkbox>
                <br />
                <div style={{ padding: "10px" }}>
                  {this.props.miniLoading ? (
                    <div className="loading">
                      <Loader /> Loading
                    </div>
                  ) : data.cod ? (
                    <Button
                      block
                      size="lg"
                      appearance="primary"
                      color="blue"
                      // className="button-default"
                      onClick={() => this.props.sync(0, true)}
                    >
                      <CheckRoundIcon className="pulse-anim" /> &nbsp;&nbsp;
                      Confirm Order ({totalAmountWithFormat})
                    </Button>
                  ) : (
                    <Checkout
                      data={data}
                      miniLoading={this.props.miniLoading}
                      setCart={this.props.setCart}
                      sync={this.props.sync}
                      autoPay={this.state.autoPay}
                    />
                  )}

                  <br />
                  <strong>Delivery partner &nbsp; </strong>
                  <img
                    src={constants.channel_ecom}
                    style={{
                      width: "auto",
                      height: "30px",
                      display: "inline-block",
                    }}
                  />
                </div>

                <br />
              </div>
            )}
          </div>
        )}
        <br />
        <Footer />
      </div>
    );
  }
}

export default CartView;

import React, { Component } from "react";
import OTPInput from "react-otp-input";
import PhoneInput from "react-phone-number-input";
import { Button, Divider, Input, InputNumber, Loader } from "rsuite";
import constants, { alertError, isValid } from "../../constants";

class CustomerData extends Component {
  state = {
    pinValidating: false,
    pinData: null,
    dummy: {
      name: this.props.customerData.name,
      address: this.props.customerData.address,
      postal_pin: this.props.customerData.postal_pin,
      phone: this.props.customerData.phone,
    },
  };

  changeDummy = (where, what) => {
    var { dummy } = this.state;
    dummy[where] = what;
    this.setState({ dummy });
  };

  checkPinCodeAndSet = (value) => {
    this.changeDummy("postal_pin", value);
    if (value.length < 6) {
      return;
    }

    this.setState({ pinValidating: true });
    this.setState({ pinData: null });
    fetch(constants.url + `check-pin/${value}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        this.setState({ pinValidating: false });
        if (data[0]?.PostOffice == null) {
          alertError("Invalid Postal Code");
          //   this.customer("pin", "");
          this.setState({ pinData: null });
          this.changeDummy("postal_pin", null);
          this.props.setCustomerData("postal_pin", null);
        } else {
          var pinData = data[0]?.PostOffice[0];
          if (pinData.State == "Kerala") {
            this.setState({ pinData });
            this.props.setCustomerData("postal_pin", value);
          } else {
            alertError(
              "Currently not accepting orders from " + pinData.State,
              6000
            );
            this.props.setCustomerData("postal_pin", null);
            this.changeDummy("postal_pin", null);
          }
        }
      })
      .catch((error) => {
        this.setState({ pinValidating: false });
        console.error(error);
      });
  };

  validateCustomerInfo = () => {
    var { dummy } = this.state;
    var { data, customerData, sync } = this.props;
    this.props.setCustomerData("name", dummy.name);
    this.props.setCustomerData("address", dummy.address);
    this.props.setCustomerData("postal_pin", dummy.postal_pin);
    this.props.setCustomerData("phone", dummy.phone);

    if (dummy?.name == null || dummy.name?.length < 3) {
      alertError("Invalid name, too short!");
      return;
    }

    if (dummy?.phone == null || dummy.phone?.length < 10) {
      alertError("Invalid phone number");
      return;
    }

    //sync if this two are not set in the data
    if (data?.phone == null || data.phone?.length < 10) {
      setTimeout(sync, 300);
      return;
    }

    if (dummy?.address == null || dummy?.address?.length < 10) {
      alertError("Invalid address");
      return;
    }

    if (dummy?.postal_pin == null || dummy.postal_pin?.length < 6) {
      alertError("Invalid postal pin");
      return;
    }

    setTimeout(sync, 300);
  };

  render() {
    var { pinValidating, pinData, dummy } = this.state;
    var { customerData, data, setCustomerData } = this.props;

    return (
      <div>
        <div>
          <Divider>Enter Your Shipping Details</Divider>
          {!isValid(data.name, 4) || !isValid(data.phone, 10) ? (
            <div style={{ margin: "10px" }} className="complete-order">
              <div>
                <label>* Your Name: {data.name}</label>
                <Input
                  placeholder="Your name"
                  value={dummy.name}
                  onChange={(value) => this.changeDummy("name", value)}
                  block
                />
              </div>
              <br />
              <div>
                <label>* Phone number:</label>
                <InputNumber
                  className="hide-step-buttons"
                  scrollable={false}
                  prefix="+91"
                  placeholder="Enter Phone Number 10 Digit"
                  value={dummy.phone}
                  onChange={(value) =>
                    value.length <= 10 && this.changeDummy("phone", value)
                  }
                />
              </div>
              <br />

              <i>Please cross check your details before saving.</i>

              <br />
            </div>
          ) : (
            <div style={{ margin: "10px" }} className="complete-order">
              <div>
                <label>* Address:</label>
                <Input
                  as="textarea"
                  rows={2}
                  placeholder="Address"
                  value={dummy.address}
                  onChange={(value) => this.changeDummy("address", value)}
                  block
                />
              </div>
              <br />

              <div>
                <label>
                  * <strong>Post Office PIN</strong> Code:{" "}
                  {pinData != null ? `(${pinData?.Name})` : ""}
                </label>
                {pinValidating ? (
                  <Loader size="xs" content="Validating your postal pin" />
                ) : (
                  <OTPInput
                    containerStyle={{
                      width: "100%",
                      height: "auto",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    inputType="number"
                    value={dummy.postal_pin}
                    onChange={this.checkPinCodeAndSet}
                    numInputs={6}
                    renderSeparator={<span>-</span>}
                    renderInput={(props) => (
                      <input {...props} className="otp-input" />
                    )}
                  />
                )}
              </div>

              <br />
            </div>
          )}

          <br />

          <Button
            block
            appearance="primary"
            disabled={this.props.miniLoading || pinValidating}
            onClick={this.validateCustomerInfo}
          >
            {this.props.miniLoading ? (
              <Loader content="saving.." />
            ) : (
              "Save Details"
            )}
          </Button>
        </div>
      </div>
    );
  }
}

export default CustomerData;

import "rsuite/dist/rsuite.min.css";
import Cookies from "js-cookie";
import StoreHome from "./store/StoreHome";
import Terms from "./store/terms";
import Contact from "./store/Contact";
import { isItOrdersPage } from "./constants";
import Orders from "./store/Orders";

function App() {
  const token = Cookies.get("token");

  //check for pay page
  // const payParam = new URLSearchParams(window.location.search).has("pay");
  // if (payParam) {
  //   return <Checkout />;
  // }

  if (isItOrdersPage()) {
    return <Orders />;
  }

  if (new URLSearchParams(window.location.search).has("terms")) {
    return <Terms />;
  }

  if (new URLSearchParams(window.location.search).has("contact")) {
    return <Contact />;
  }

  return <StoreHome />;
}

export default App;

import React, { Component } from "react";
import constants, { alertError, alertInfo, play } from "../constants";
import Cookies from "js-cookie";
import { Button, Loader } from "rsuite";

class Checkout extends Component {
  state = {
    paymentId: "",
    uid: Cookies.get("my_uid"),
    loading: false,
  };

  componentDidMount = () => {
    if (this.props.autoPay) {
      setTimeout(this.handlePayment, 1300);
    }
  };

  handlePayment = async () => {
    this.setState({ loading: true });
    var { data } = this.props;
    try {
      // Make a request to your Laravel API to create a Razorpay order
      const response = await fetch(constants.url + `pay/order`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          // Include any other headers you need, such as authentication headers
        },
        // Include any necessary data for creating the order
        body: JSON.stringify({ uid: this.state.uid }), // Adjust amount as needed
      });

      if (!response.ok) {
        // If the response status is not okay, handle the error
        throw new Error(`Error: ${response.status} - ${response.statusText}`);
      }

      const responseData = await response.json();

      // Initialize Razorpay (Note: Razorpay is assumed to be available globally)
      const razorpayOptions = {
        key: constants.RAZOR_KEY_ID,
        amount: responseData.order.amount,
        order_id: responseData.order.id,
        currency: "INR",
        name: "SalesMart.In",
        description: "Secure Payment by Razorpay",
        uid: this.state.uid,
        prefill: {
          name: data.name,
          email: "support@salesmart.in",
        },
        handler: (response) => {
          // Handle success callback
          console.log(response);
          this.setState({ paymentId: response.razorpay_payment_id });

          // Make a request to your Laravel API to verify the payment
          fetch(constants.url + "pay/verify", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              payment_id: response.razorpay_payment_id,
              order_id: responseData.order.id,
              uid: this.state.uid,
            }),
          })
            .then((verificationResponse) => verificationResponse.json())
            .then((verificationData) => {
              // Handle payment verification on the server
              if (verificationData?.status == "success") {
                alertInfo(verificationData?.message);
                Cookies.remove("cart");
                Cookies.remove("my_uid");
                Cookies.set("sale_uid", verificationData.sale_uid);
                setTimeout(() => {
                  window.location.href = `https://track.salesmart.in/?${verificationData.sale_uid}`;
                }, 1000);
              } else {
                alertError(
                  "Payment verification failed, please contact Customer support",
                  10000
                );
                this.setState({ loading: false });
              }
            })
            .catch((error) => {
              console.error(error);
              alertError(
                "Payment verification failed, please contact Customer support",
                10000
              );
              this.setState({ loading: false });
            });
        },
      };

      // Open Razorpay checkout form
      const razorpayInstance = new window.Razorpay(razorpayOptions);
      razorpayInstance.open();
      this.setState({ loading: false });
    } catch (error) {
      console.log("handlepayment error");
      this.props.sync();
    }
  };

  render() {
    var { data } = this.props;
    var fullAmount = new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "INR",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(
      parseFloat(data.channel_rate) +
        parseFloat(data.total_amount) +
        parseFloat(data.cod_fee)
    );
    return (
      <div>
        <Button
          appearance="primary"
          block
          onClick={this.handlePayment}
          disabled={this.props.miniLoading || this.state.loading}
          size="lg"
        >
          {this.state.loading && <Loader content=" " />}
          {data.cod
            ? `Pay ₹${data.cod_fee} to Confirm your order`
            : `Pay ${fullAmount} to Finish your order`}
        </Button>
        {/* {this.state.paymentId && <p>Payment ID: {this.state.paymentId}</p>} */}
        <br />
        <span className="center-horizontal">
          Secured with:{" "}
          <img
            src={constants.razorpay}
            style={{ height: "14px", width: "auto" }}
          />{" "}
          &nbsp;&nbsp;{" "}
          <img
            src={constants.channel_ecom}
            style={{ height: "14px", width: "auto" }}
          />
        </span>
      </div>
    );
  }
}

export default Checkout;

import React, { Component } from "react";
import GearIcon from "@rsuite/icons/Gear";
import AppSelectIcon from "@rsuite/icons/AppSelect";
import { Button, Drawer, Dropdown } from "rsuite";
import OffIcon from "@rsuite/icons/Off";
import OthersIcon from "@rsuite/icons/Others";
import ScatterIcon from "@rsuite/icons/Scatter";
import AdminIcon from "@rsuite/icons/Admin";
import MemberIcon from "@rsuite/icons/Member";
import { isItCartPage, isItOrdersPage } from "../constants";
import Cookies from "js-cookie";
import GLogin from "./GLogin";

class UserNavButton extends Component {
  state = {
    loginDrawer: false,
  };

  loginDrawer = (loginDrawer = true) => {
    this.setState({ loginDrawer });
  };

  goto = (where) => {
    window.location.href =
      window.location.origin + window.location.pathname + where;
  };

  render() {
    var uid = Cookies.get("uid");

    return (
      <>
        {uid ? (
          <Dropdown title="" icon={<GearIcon />}>
            {!isItOrdersPage() && (
              <Dropdown.Item
                icon={<AppSelectIcon />}
                onSelect={() => this.goto("?orders")}
              >
                Orders
              </Dropdown.Item>
            )}
            <Dropdown.Item
              icon={<ScatterIcon />}
              onSelect={() => this.goto("")}
            >
              Home
            </Dropdown.Item>
            <Dropdown.Item
              icon={<OffIcon />}
              onSelect={() => {
                Cookies.remove("uid");
                this.goto("");
              }}
            >
              Logout
            </Dropdown.Item>
          </Dropdown>
        ) : (
          <Button
            appearance="primary"
            style={{ background: "#f7b77f", borderRadius: "15px" }}
            onClick={this.loginDrawer}
          >
            <OthersIcon style={{ color: "white", fontWeight: "bold" }} />{" "}
          </Button>
        )}

        <Drawer
          open={this.state.loginDrawer}
          onClose={() => this.loginDrawer(false)}
          placement="bottom"
        >
          <Drawer.Header>
            <Drawer.Title>SalesMart.In - Login</Drawer.Title>
          </Drawer.Header>
          <Drawer.Body>
            <GLogin onComplete={(data) => this.loginDrawer(false)} />
          </Drawer.Body>
        </Drawer>
      </>
    );
  }
}

export default UserNavButton;

import logo from "./images/logo.png";
import full_logo from "./images/old_logo.png";
import logoIcon from "./images/se_icon.png";
import channel_ecom from "./images/channel_ecom.png";
import channel_dtdc from "./images/channel_dtdc.png";
import sound_1 from "./sounds/sound_1.mp3";
import sound_2 from "./sounds/sound_2.mp3";
import sound_3 from "./sounds/sound_3.mp3";
import razorpay from "./images/razorpay.png";
import google from "./images/google.png";
import imagePlaceholder from "./images/image-placeholder.webp";
import { Message, toaster } from "rsuite";

function getShopTypeName(id) {}

// const parent = "http://192.168.1.2:8000/";

// const parent = "http://localhost:8000/";
// const RAZOR_KEY_ID = "rzp_test_qdwepRw5gDPVto";

const parent = "https://api.salesmart.in/";
const RAZOR_KEY_ID = "rzp_live_P271enwK6qSosV";

const constants = {
  // url: "http://localhost:5656/api/",
  // root_url: "http://localhost:5656/",
  // url: "https://sawari-api.stack.a2hosted.com/",
  // root_url: "https://sawari-api.stack.a2hosted.com/",
  // url: "https://api.stackenterprises.in/api/",
  url_parent: parent,
  url: parent + "api/",
  imagePath: parent + "storage/customer-images/",
  root_url: "https://sawari-api.herokuapp.com/",
  // map_api: "AIzaSyB8R5HSZM8XqkZnPSlFlDShG3xn3u3yaMw",
  map_api: "AIzaSyD-DGfJYGW-ZYyssUoXlOj9BwzllSYBiVs",
  GOOGLE_CLIENT_ID:
    "91963764005-l8h2ou6gcqfet345va4n5s69ks0f87gn.apps.googleusercontent.com",
  RAZOR_KEY_ID,
  logo,
  full_logo,
  logoIcon,
  razorpay,
  google,
  channel_ecom,
  imagePlaceholder,
  LIST_MODE: 11,
  VIEW_MODE: 12,
  CREATE_MODE: 13,
  EDIT_MODE: 14,
  shopTypes: [
    { label: "Mini Mart", value: "1" },
    { label: "Super Market", value: "2" },
    { label: "Hyper Market", value: "3" },
    { label: "Electricals", value: "4" },
    { label: "Others", value: "5" },
    { label: "RBP", value: "6" },
    { label: "Online", value: "7" },
  ],
  salesStatuses: [
    { label: "Init", value: "init" },
    { label: "Pending", value: "pending" },
    { label: "Confirmed", value: "confirmed" },
    { label: "Delivery", value: "delivery" },
    { label: "Paid Cash", value: "paid-cash" },
    { label: "Paid UPI", value: "paid-upi" },
    { label: "Complete", value: "complete" },
    { label: "Cancelled", value: "cancelled" },
  ],
};

export function getShopTypeLabel(value) {
  const shopType = constants.shopTypes.find((type) => type.value == value);
  return shopType ? shopType.label : "";
}

export function isInCurrentMonth(dateString) {
  var date = new Date(dateString);
  const now = new Date();
  return (
    date.getMonth() === now.getMonth() &&
    date.getFullYear() === now.getFullYear()
  );
}

export function isToday(dateString) {
  var date = new Date(dateString);
  const today = new Date();
  return (
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
  );
}

export function shortenedText(text, maxLength) {
  if (!text) {
    return <span></span>;
  }

  if (text.length <= maxLength) {
    return <span>{text}</span>;
  }

  return <span>{`${text.slice(0, maxLength) ?? ""}...`}</span>;
}

export function getCurrentDateTime() {
  const currentDate = new Date();

  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, "0");
  const day = String(currentDate.getDate()).padStart(2, "0");

  const hours = String(currentDate.getHours()).padStart(2, "0");
  const minutes = String(currentDate.getMinutes()).padStart(2, "0");
  const seconds = String(currentDate.getSeconds()).padStart(2, "0");

  const formattedDateTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

  return formattedDateTime;
}

export function getListFromArray(arr) {
  return arr.map((item) => ({ label: item, value: item }));
}

export function haversineDistance(coord1, coord2) {
  const earthRadius = 6371000; // Radius of the Earth in meters

  const degToRad = (deg) => deg * (Math.PI / 180);

  const lat1 = degToRad(parseFloat(coord1.lat));
  const lon1 = degToRad(parseFloat(coord1.lng));
  const lat2 = degToRad(parseFloat(coord2.lat));
  const lon2 = degToRad(parseFloat(coord2.lng));

  if (!lat1 || !lat2 || !lon1 || !lon2) {
    console.log("invalid lat or lon, from haversineDistance constants.js");
    return 0;
  }

  const dLat = lat2 - lat1;
  const dLon = lon2 - lon1;

  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(lat1) * Math.cos(lat2) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  const distance = earthRadius * c;
  window.debugToast("haversine distance " + distance);
  return distance;
}

export function getProductImage(product) {
  return parent + "storage/" + product?.image;
}

export function alertError(msg, duration = 3000) {
  play(2);
  toaster.push(<Message type="error">{msg}</Message>, {
    duration,
    placement: "topCenter",
  });
  navigator.vibrate(200);
}

export function alertInfo(msg, duration = 3000) {
  play(3);
  toaster.push(<Message type="success">{msg}</Message>, {
    duration,
    placement: "topCenter",
  });
}

export function isItCartPage() {
  return new URLSearchParams(window.location.search).has("cart");
}
export function isItOrdersPage() {
  return new URLSearchParams(window.location.search).has("orders");
}

export function urlHas(param) {
  return new URLSearchParams(window.location.search).has(param);
}

export function pushUrl(param = null) {
  window.history.pushState(
    { path: "" },
    "",
    window.location.origin +
      window.location.pathname +
      (param ? "?" + param : "")
  );
}

export function play(which) {
  var sounds = [sound_1, sound_2, sound_3];
  const audio = new Audio(sounds[which - 1]);
  audio.play();
}

export function scrollTop() {
  window.scrollTo({
    top: 0,
    behavior: "smooth", // Smooth scrolling animation
  });
}

export function isValid(obj, lngt) {
  return obj !== null && obj.length >= lngt;
}

export default constants;

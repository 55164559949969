// Product.js

import React from "react";
import { Button, ButtonGroup, ButtonToolbar, Rate, Tag } from "rsuite";
import { getProductImage } from "../constants";

const Product = ({
  product,
  addProduct,
  removeProduct,
  isInCart,
  click,
  miniLoading,
  mini,
}) => {
  return (
    <div className={`product ${mini ? "mini-product" : ""}`}>
      <img
        src={getProductImage(product)}
        alt={product.name}
        className="product-image"
        onClick={click}
      />
      <div className="product-details">
        <h3 className="product-name" onClick={click}>
          {product.name}
        </h3>
        {!mini && (
          <Rate
            value={product.rating}
            size="xs"
            allowHalf={true}
            readOnly={true}
            style={{ width: "100px" }}
          />
        )}
        {!mini && (
          <span>
            <Tag size="sm">{product.category_name}</Tag>
          </span>
        )}
        <span onClick={click}>
          <span className={` ${mini ? "mini-unit-price" : "unit-price"}`}>
            ₹{product.unit_price}
          </span>
          {product.old_price && !mini && (
            <span className="old-price">₹{product.old_price}</span>
          )}
        </span>

        {!mini && (
          <ul className="prod-feature-list" onClick={click}>
            {product.warranty != 0 && <li>Warranty Available</li>}
            {product.free_ship != 0 && <li>Free Delivery</li>}
            <li>Minimum Order {product.min_qty} Nos</li>
            <li
              style={{
                color: product.current_stock <= 5 ? "red" : "rgb(7, 122, 24)",
              }}
            >
              {product.current_stock == 0
                ? "Out of Stock"
                : product.current_stock <= 5
                ? "Limited Stock"
                : "Available Stock"}
            </li>
          </ul>
        )}

        <div className="add-to-cart-btn-container">
          {isInCart(product) != null ? (
            <ButtonToolbar>
              <ButtonGroup>
                <Button
                  appearance="ghost"
                  onClick={() => removeProduct(product)}
                  disabled={miniLoading}
                >
                  -
                </Button>
                <Button appearance="ghost">{isInCart(product)}</Button>
                <Button
                  appearance="ghost"
                  onClick={() => addProduct(product)}
                  disabled={miniLoading}
                >
                  +
                </Button>
              </ButtonGroup>
            </ButtonToolbar>
          ) : product.current_stock == 0 ? (
            <button className="add-to-cart-btn out-of-stock">
              OUT OF STOCK
            </button>
          ) : (
            <button
              onClick={() => addProduct(product)}
              className="add-to-cart-btn"
            >
              ADD
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default Product;

// Product.js

import React from "react";
import { Button, ButtonGroup, ButtonToolbar, Message, Rate, Tag } from "rsuite";
import { getProductImage } from "../constants";

const BigProduct = ({
  product,
  addProduct,
  removeProduct,
  isInCart,
  miniLoading,
  gotoCart,
}) => {
  return (
    <div className="">
      <img
        src={getProductImage(product)}
        alt={product.name}
        className="big-product-image"
        data-action="zoom"
      />
      <div className="product-details">
        <br />
        <h3 className="product-name">{product.name}</h3>

        <span style={{ display: "inline-block" }}>
          <Rate
            value={product.rating}
            size="xs"
            allowHalf={true}
            readOnly={true}
          />{" "}
          ({product.rating})
        </span>

        <span>
          <Tag size="sm">{product.category_name}</Tag>
        </span>
        <br />
        <span>
          <span className="unit-price">₹{product.unit_price}</span>
          {product.old_price && (
            <span className="old-price">₹{product.old_price}</span>
          )}
        </span>
        <ul className="prod-feature-list">
          {product.old_price ? (
            <li>
              {Math.round(
                ((product.old_price - product.unit_price) / product.old_price) *
                  100
              )}
              % Discount
            </li>
          ) : (
            ""
          )}
          {product.warranty != 0 && <li>Warranty Available</li>}
          {product.free_ship != 0 && <li>Free Delivery</li>}
          <li>Minimum Order {product.min_qty} Nos</li>
          <li
            style={{
              color: product.current_stock <= 5 ? "red" : "rgb(7, 122, 24)",
            }}
          >
            {product.current_stock == 0
              ? "Out of Stock"
              : product.current_stock <= 5
              ? "Limited Stock"
              : "Available Stock"}
          </li>
          <li>Weight: {product.unit_weight} gm</li>
        </ul>
        <Message type="info">Cash on delivery is available now!</Message>
        <br />
        {isInCart(product) != null ? (
          <ButtonToolbar>
            <ButtonGroup>
              <Button
                appearance="ghost"
                onClick={() => removeProduct(product)}
                disabled={miniLoading}
              >
                -
              </Button>
              <Button appearance="ghost">{isInCart(product)}</Button>
              <Button
                appearance="ghost"
                onClick={() => addProduct(product)}
                disabled={miniLoading}
              >
                +
              </Button>
              <Button
                appearance="ghost"
                color="green"
                onClick={gotoCart}
                disabled={miniLoading}
              >
                Go to cart
              </Button>
            </ButtonGroup>
          </ButtonToolbar>
        ) : product.current_stock == 0 ? (
          <button className="add-to-cart-btn out-of-stock">OUT OF STOCK</button>
        ) : (
          <button
            onClick={() => {
              addProduct(product);
              gotoCart();
            }}
            className="button"
          >
            Buy Now
          </button>
        )}
      </div>
      <br />
      <strong>Product Description</strong>
      <br />
      <p style={{ whiteSpace: "pre-wrap", padding: "10px" }}>
        {product.description}
      </p>
    </div>
  );
};

export default BigProduct;

import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import React, { Component } from "react";
import PhoneInput from "react-phone-number-input";
import { Button, Divider, Input, Message } from "rsuite";
import constants, { alertError, alertInfo } from "../constants";
import { auth } from "../firebase";
import "react-phone-number-input/style.css";
import OTPInput from "react-otp-input";
import Cookies from "js-cookie";

class GLogin extends Component {
  state = {
    phone: null,
    otp: null,
    confirmation: null,
    user: null,
    uid: null,
    loading: false,
  };

  setLoading = (loading = true) => {
    this.setState({ loading });
  };

  sendOtp = async () => {
    console.log(this.state.phone);
    try {
      this.setLoading();
      var recaptcha = await new RecaptchaVerifier(auth, "recaptcha", {
        size: "invisible",
      });
      var confirmation = await signInWithPhoneNumber(
        auth,
        this.state.phone,
        recaptcha
      );
      console.log(confirmation);
      if (confirmation.verificationId) {
        this.setState({ otpEnterMode: true, confirmation });
        alertInfo("OTP has been Sent to " + this.state.phone);
      }
      this.setLoading(false);
    } catch (err) {
      console.log(err);
      alertError("Unable to login, please try again");
      this.setLoading(false);
    }
  };

  verifyOtp = () => {
    this.setLoading();
    this.state.confirmation
      .confirm(this.state.otp)
      .then((result) => {
        // User signed in successfully.
        const user = result.user;
        console.log(user);
        this.setState({ user });

        fetch(constants.url + `public-login`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ idToken: user?.stsTokenManager?.accessToken }),
        })
          .then((response) => response.json())
          .then((data) => {
            if (data?.uid) {
              Cookies.set("uid", data.uid);
              this.setState({ uid: data.uid });
              var msg = "Login success";
              if (data?.person_name) {
                msg = `Hello ${data?.person_name}, welcome back`;
              }
              alertInfo(msg);
              this.props?.onComplete(data);
            }
            this.setLoading(false);
          })
          .catch((error) => {
            console.error(error);
            //   this.setLoading(false);
            alertError("Unable to login, please try again");
            this.setState({ confirmation: null, otp: null, phone: null });
            this.setLoading(false);
          });
      })
      .catch((error) => {
        // User couldn't sign in (bad verification code?)
        alertError("Unable to login, please try again");
        this.setState({ confirmation: null, otp: null, phone: null });
        this.setLoading(false);
      });
  };

  render() {
    var { loading } = this.state;
    if (this.state.user || this.state.uid) {
      return (
        <div>
          <Message type="success">
            Login success, please wait for the navigation..
          </Message>
        </div>
      );
    }

    if (this.state.confirmation) {
      return (
        <div>
          <Divider>Enter OTP sent to {this.state.phone}</Divider>
          <OTPInput
            containerStyle={{
              width: "100%",
              height: "auto",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
            }}
            inputType="number"
            value={this.state.otp}
            onChange={(otp) => {
              this.setState({ otp });
              if (otp.length == 6) {
                this.verifyOtp();
              }
            }}
            numInputs={6}
            renderSeparator={<span>-</span>}
            renderInput={(props) => <input {...props} className="otp-input" />}
          />
          <br />
          <Button
            onClick={this.verifyOtp}
            appearance="primary"
            block
            disabled={loading}
          >
            Verify OTP
          </Button>
        </div>
      );
    }
    return (
      <div className="center-vertical">
        <Divider>Enter your phone number to continue</Divider>
        <PhoneInput
          containerStyle={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
          }}
          defaultCountry="IN"
          placeholder="Enter Phone Number 10 Digit"
          value={this.state.phone}
          onChange={(phone) => this.setState({ phone })}
        />
        <br />
        <div id="recaptcha"></div>
        <br />
        <Button
          onClick={this.sendOtp}
          appearance="primary"
          block
          disabled={loading}
        >
          Send OTP
        </Button>
        <br />
        <span className="center-horizontal">
          Secured with{" "}
          <img
            src={constants.google}
            style={{ height: "24px", width: "auto" }}
          />
          &nbsp; Google Firebase Authentication
        </span>
      </div>
    );
  }
}

export default GLogin;

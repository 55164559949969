import React, { Component } from "react";

import salesmartIcon from "../images/salesmart_icon.png";

class Footer extends Component {
  state = {};
  render() {
    return (
      <div>
        <div className="footer">
          <img src={salesmartIcon} />
          <div>
            <strong style={{ fontSize: "16px !important" }}>
              SalesMart.In by Stack Enterprises
            </strong>
            <br />
            <i>12/444 Varkala Road - Paravur Kollam</i>
            <br />
            <i>Kerala - 691301</i>
            <br />
            <strong>Customer Care: 9037063936 and 8139063936</strong>
            <br />
            <strong>Email: sales@salesmart.in</strong>
            <br />
            <a
              href={`${
                window.location.origin + window.location.pathname
              }?contact`}
              style={{ color: "white" }}
            >
              Contact US
            </a>
          </div>
        </div>
        <span>
          By using our website, you consent to our{" "}
          <a
            href={`${window.location.origin + window.location.pathname}?terms`}
          >
            T&amp;C and privacy policy
          </a>
          .
        </span>
      </div>
    );
  }
}

export default Footer;

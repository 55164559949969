import React, { Component } from "react";

import stackStore from "../images/stack-store.png";
import homeIcon from "../images/icon-home.png";
import { Button, List, Loader, Stack, Tag } from "rsuite";
import Cookies from "js-cookie";
import UserNavButton from "./UserNavButton";
import Footer from "./footer";
import constants from "../constants";

class Orders extends Component {
  state = {
    uid: Cookies.get("uid"),
    loading: false,
    orders: [],
  };

  setLoading = (loading = true) => {
    this.setState({ loading });
  };

  componentDidMount = () => {
    var uid = Cookies.get("uid");
    if (!uid) {
      this.back();
    } else {
      this.loadOrder(uid);
    }
  };

  loadOrder = (uid) => {
    this.setLoading();
    fetch(constants.url + "my-orders", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        uid,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({ orders: data.sales });
        this.setLoading(false);
      })
      .catch((error) => {
        this.setLoading(false);
        console.error(error);
      });
  };

  back = () => {
    window.location.href = "/";
  };

  render() {
    var { orders } = this.state;
    return (
      <div>
        <header className="store-header">
          <img src={stackStore} alt="Stack Logo" className="store-logo" />
          <div>
            <UserNavButton />
            <button className="cart-button" onClick={this.back}>
              <img src={homeIcon} />
            </button>
          </div>
        </header>
        <Stack justifyContent="space-between">
          <Button appearance="link" onClick={this.back}>
            Back to home
          </Button>
          <strong>Orders</strong>
        </Stack>
        <hr />

        {this.state.loading && (
          <div className="loading">
            <Loader size="md" content="loading.." />
          </div>
        )}
        <List style={{ width: "90%", display: "block", margin: "auto" }}>
          {orders.map((order, index) => {
            return (
              <List.Item key={index}>
                <h1>
                  Order Id# {order.id} Date: {order.date}
                </h1>
                <Tag color="green">{order.status}</Tag>
                {order.cod ? <Tag color="blue">Cash on delivery</Tag> : ""}
                <br />
                <br />
                <Stack justifyContent="space-between">
                  <span>Order Value:</span>
                  <strong>{order.total_amount}</strong>
                </Stack>
                <br />
                <Stack justifyContent="space-between">
                  <span>Total Weight:</span>
                  <strong>{order.total_weight}</strong>
                </Stack>
                <br />
                <Stack justifyContent="space-between">
                  <span>Estimated Delivery:</span>
                  <strong>{order.delivery_date}</strong>
                </Stack>
                <br />
                <Stack justifyContent="space-between">
                  <span>Delivery Channel:</span>
                  <strong>
                    <img
                      src={constants.channel_ecom}
                      style={{ height: "40px", width: "auto" }}
                    />
                  </strong>
                </Stack>
              </List.Item>
            );
          })}
        </List>
        <br />
        <Footer />
      </div>
    );
  }
}

export default Orders;

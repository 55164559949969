import React, { Component } from "react";
import { Button } from "rsuite";
import stackStore from "../images/stack-store.png";

class Contact extends Component {
  state = {};

  render() {
    return (
      <div>
        <header className="store-header">
          <img src={stackStore} alt="Stack Logo" className="store-logo" />
          <div>Contact Us</div>
        </header>

        <div style={sectionStyle}>
          <p style={infoStyle}>
            <strong>Address:</strong> 12/444 Varkala Road Paravur Kollam, Kerala
            691301
          </p>
          <p style={infoStyle}>
            <strong>Customer Care:</strong> 9037063936
          </p>
          <p style={infoStyle}>
            <strong>Technical Support:</strong> 8139043936
          </p>
          <p style={infoStyle}>
            <strong>Sales Queries:</strong> sales@salesmart.in
          </p>
        </div>

        <div style={sectionStyle}>
          <p style={infoStyle}>
            Stack Enterprises is a modern electronics manufacturing company that
            supports re-branding and marketing support. We are a leading
            supplier of materials to the lighting industry, providing
            high-quality products and on-site training to meet the diverse needs
            of our customers.
          </p>
        </div>
        <Button
          appearance="primary"
          block
          color="yellow"
          onClick={() => {
            window.location.href =
              window.location.origin + window.location.pathname;
          }}
        >
          Back to Home
        </Button>
      </div>
    );
  }
}

const headerStyle = {
  backgroundColor: "#f0f0f0",
  padding: "10px",
  textAlign: "center",
};

const logoStyle = {
  maxWidth: "100px", // Adjust the size as needed
};

const titleStyle = {
  fontSize: "1.5em",
  fontWeight: "bold",
  marginTop: "10px",
};

const sectionStyle = {
  margin: "20px",
};

const infoStyle = {
  marginBottom: "10px",
};

export default Contact;

import Cookies from "js-cookie";
import React, { Component } from "react";
import {
  Affix,
  Badge,
  Breadcrumb,
  Button,
  Divider,
  Drawer,
  Loader,
  Message,
  toaster,
} from "rsuite";
import constants, {
  alertError,
  alertInfo,
  isItCartPage,
  play,
  scrollTop,
} from "../constants";
import cartIcon from "../images/icon-cart.png";
import ArowBackIcon from "@rsuite/icons/ArowBack";
import salesMart from "../images/sales_mart.png";
import BigProduct from "./BigProduct";
import CartView from "./CartView";
import { withRouter } from "react-router-dom";

// import logo from './logo.png'; // Replace with your actual logo image
import Product from "./Product"; // Create a Product component
// import FinishOrderButton from './FinishOrderButton'; // Create a FinishOrderButton component
import "./StoreHome.css";
import GLogin from "./GLogin";
import UserNavButton from "./UserNavButton";
import Footer from "./footer";

class StoreHome extends Component {
  state = {
    cart: [],
    products: [],
    loading: false,
    miniLoading: false,
    selected: null,
    productViewer: false,
    cartView: false,

    cod: null,
    uid: Cookies.get("my_uid"),

    data: null,

    customerData: {
      name: null,
      address: null,
      postal_pin: null,
      phone: null,
    },
  };

  componentDidMount = () => {
    this.loadProducts();
    window.addEventListener("popstate", this.handlePopstate);
    setTimeout(this.sync, 1000);

    // start tawk.to api
    setTimeout(() => {
      window.Tawk_API.start();
      window.Tawk_API.showWidget();
      console.log("tawk to start called..");
    }, 5000);
  };

  componentWillUnmount() {
    // Remove event listener when the component unmounts to avoid memory leaks
    window.removeEventListener("popstate", this.handlePopstate);
  }

  setLoading = (loading = true, miniLoading = false) => {
    if (!loading) {
      this.setState({ loading, miniLoading: false });
    } else if (miniLoading) {
      this.setState({ miniLoading: true });
    } else {
      this.setState({ loading, miniLoading: true });
    }
  };

  loadProducts = () => {
    this.setLoading();
    fetch(constants.url + "store-products", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        this.setState({ products: data.products });
        this.setLoading(false);
        setTimeout(this.getCart, 200);
        setTimeout(this.checkUrlParam, 200);
      })
      .catch((error) => {
        this.setLoading(false);
        this.loadProducts();
        console.error(error);
      });
  };

  productViewer = (productViewer = true, selected = null) => {
    this.setState({ productViewer, selected });
    if (!productViewer) {
      this.updateProductUrl();
    }
    scrollTop();
  };

  getCart = () => {
    var cartJSON = Cookies.get("cart");

    if (cartJSON) {
      // The "cart" cookie exists, so we can parse it and update the state
      var cart = JSON.parse(cartJSON);
      // Filter out cart items whose product_id is not in the products array
      cart = cart.filter((cartItem) =>
        this.state.products.some(
          (product) => product.id === cartItem.product_id
        )
      );
      this.setState({ cart }, () => {
        //check for cart url
        if (new URLSearchParams(window.location.search).has("cart")) {
          this.checkForZeroCart(cart, false, true);
        }
      });
    } else {
      // The "cart" cookie doesn't exist, so initialize cart as an empty array
      const cart = [];
      this.setState({ cart });
    }
  };

  // Function to add a product to the cart
  addProduct = (product) => {
    //CHECK IF USER PLACE ANY ORDER BEFORE, SO PREVENT ADDING NEW PRODUCTS..
    if (Cookies.get("order_placed")) {
      alertError("You have an order which is still processing..");
      return;
    }

    const { cart } = this.state;

    // Check if the product is already in the cart
    const existingProduct = cart.find((item) => item.product_id === product.id);

    if (existingProduct) {
      // If the product is already in the cart, update the quantity with additional checks
      if (
        existingProduct.qty < Math.min(product.max_qty, product.current_stock)
      ) {
        existingProduct.qty += 1;
      } else {
        // Handle the case where the quantity is already at the maximum
        console.log(`Maximum quantity reached for product ${product.id}`);
        // You can add additional logic here if needed
      }
    } else {
      // If the product is not in the cart, add it with quantity based on min_qty
      const initialQty = Math.max(product.min_qty, 1); // Use min_qty or 1 if min_qty is not set
      cart.push({ product_id: product.id, qty: initialQty });
    }

    // Update the state and save the cart in cookies
    this.setState({ cart }, () => {
      Cookies.set("cart", JSON.stringify(cart));
      this.sync();
      play(1);
    });
  };

  removeProduct = (product) => {
    const { cart } = this.state;

    // Find the product in the cart
    const existingProduct = cart.find((item) => item.product_id === product.id);

    if (existingProduct) {
      // If the product is in the cart, decrease the quantity
      existingProduct.qty -= 1;

      // Check if the quantity is less than or equal to min_qty
      if (existingProduct.qty < product.min_qty) {
        // If true, remove the product from the cart
        const updatedCart = cart.filter(
          (item) => item.product_id !== product.id
        );

        // Update the state and save the cart in cookies
        this.setState({ cart: updatedCart }, () => {
          Cookies.set("cart", JSON.stringify(updatedCart));
          this.checkForZeroCart(updatedCart);
          this.sync();
          play(2);
        });
      } else {
        // If the quantity is greater than min_qty, update the state and save the cart in cookies
        this.setState({ cart }, () => {
          Cookies.set("cart", JSON.stringify(cart));
          this.checkForZeroCart(cart);
          this.sync();
          play(2);
        });
      }
    }
  };

  checkForZeroCart = (cart, alert = false, openCartView = false) => {
    console.log(`check for zero cart length ${cart.length}`);
    if (cart.length == 0) {
      this.setState({ cartView: false });

      if (alert) {
        alertError("Your cart is empty, please add items!");
      }
    } else if (openCartView) {
      this.setState({ cartView: true });
    }

    return cart.length == 0;
  };

  isInCart = (product) => {
    const { cart } = this.state;

    const foundProduct = cart.find((item) => item.product_id === product.id);

    // If the product is in the cart, return its quantity; otherwise, return null
    return foundProduct ? foundProduct.qty : null;
  };

  updateProductUrl = (product = null) => {
    var newUrl = window.location.origin + window.location.pathname;
    newUrl += product ? `?product=${product.id}` : "";

    window.history.pushState({ path: newUrl }, "", newUrl);
  };

  checkUrlParam = () => {
    const urlSearchParams = new URLSearchParams(window.location.search);

    // Check if the "product" parameter exists
    if (urlSearchParams.has("product")) {
      // Get the current value of the "product" parameter
      const currentProductValue = urlSearchParams.get("product");
      var foundProduct = this.state.products.find(
        (product) => product.id == currentProductValue
      );
      if (foundProduct) {
        this.productViewer(true, foundProduct);
      } else {
        this.updateProductUrl();
      }
    }
  };

  gotoCart = (goBack = false) => {
    if (goBack) {
      this.setState({ cartView: false });
      this.productViewer(false);
    } else {
      if (!this.checkForZeroCart(this.state.cart, true, true)) {
        window.history.pushState(
          { path: "" },
          "",
          window.location.origin + window.location.pathname + "?cart"
        );
      }
    }
  };

  handlePopstate = (event) => {
    //BROWSER BACKWARDS..
    //console.log("User navigated back or forward in browser history!");
    this.gotoCart(true);
    this.productViewer(false, null);
  };

  setCustomerData = (where, what) => {
    var { customerData } = this.state;
    customerData[where] = what;
    this.setState({ customerData });
  };

  // updateOrder = (finish = false) => {
  //   var uid = Cookies.get("uid");
  //   var { name, address, postal_pin } = this.state.customerData;
  //   if (uid) {
  //     this.setLoading(true, !finish);
  //     fetch(constants.url + `update-order`, {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify({
  //         cart: this.state.cart,
  //         finish: finish ? 1 : 0,
  //         uid,
  //         name,
  //         address,
  //         postal_pin,
  //         cod: this.state.cod,
  //         load_shipping: isItCartPage(),
  //       }),
  //     })
  //       .then((response) => response.json())
  //       .then((data) => {
  //         if (!data) {
  //           this.updateOrder(finish);
  //         }
  //         this.setState({ data });
  //         this.setLoading(false);
  //         if (finish) {
  //           this.setState({ cart: [] });
  //           Cookies.remove("cart");
  //         }
  //       })
  //       .catch((error) => {
  //         console.error(error);
  //         this.updateOrder(finish);
  //         this.setLoading(false);
  //       });
  //   }
  // };

  sync = (retry = 0, finish = false) => {
    var { customerData, uid, cod, cart } = this.state;
    var req = {
      name: customerData.name,
      address: customerData.address,
      postal_pin: customerData.postal_pin,
      phone: customerData.phone,
      cod,
      cart,
      load_shipping: isItCartPage(),
      finish,
    };
    if (uid) {
      req.uid = uid;
    }

    this.setLoading(true, true);

    fetch(constants.url + `sync`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(req),
    })
      .then((response) => response.json())
      .then((d) => {
        var data = d.tempCustomer;

        if (!data) {
          if (retry < 3) {
            this.sync(retry + 1);
          }
        }

        if (data?.cod == 1 && data?.cod_fee == 0 && data.postal_pin) {
          console.log(
            "cod true and cod fee is zero, also has postal pin set.."
          );
          this.sync();
        }

        Cookies.set("my_uid", data.uid);

        this.setState({
          data,
          customerData: {
            name: data.name,
            address: data.address,
            postal_pin: data.postal_pin,
            phone: data.phone,
          },
          uid: data.uid,
        });
        this.setLoading(false);
        if (finish) {
          alertInfo("Order confirmed!");
          Cookies.remove("cart");
          Cookies.remove("my_uid");
          Cookies.set("order_placed", 1);
          setTimeout(() => (window.location.href = "/"), 3500);
        }
      })
      .catch((error) => {
        console.error(error);
        if (retry < 3) {
          if (retry == 1) {
            Cookies.remove("my_uid");
          }
          this.sync(retry + 1);
        }
        this.setLoading(false);
      });
  };

  render() {
    // Sample product data
    var { products, selected, cartView, cart, data } = this.state;

    if (cartView && isItCartPage()) {
      return (
        <CartView
          products={products}
          cart={cart}
          setCart={(cart, done) => this.setState({ cart }, done)}
          uid={this.state.uid}
          // setUid={(uid) => this.setState({ uid }, this.updateOrder)}
          cod={this.state.cod}
          setCod={(type, cod) => this.setState({ cod }, this.sync)}
          data={data}
          setData={(data) => this.setState({ data })}
          customerData={this.state.customerData}
          setCustomerData={this.setCustomerData}
          back={() => this.gotoCart(true)}
          addProduct={this.addProduct}
          removeProduct={this.removeProduct}
          isInCart={this.isInCart}
          productViewer={this.productViewer}
          setLoading={this.setLoading}
          miniLoading={this.state.miniLoading}
          sync={this.sync}
        />
      );
    }

    return (
      <div className="store-home">
        <Affix>
          <header className="store-header">
            <img src={salesMart} alt="Stack Logo" className="store-logo" />
            <div className={this.state.cart.length > 0 ? "pop-anim" : ""}>
              {/* <UserNavButton /> */}
              <Badge content={this.state.cart.length}>
                <button
                  className="cart-button"
                  onClick={() => this.gotoCart(false)}
                >
                  <img src={cartIcon} />
                </button>
              </Badge>
            </div>
          </header>
        </Affix>
        {Cookies.get("sale_uid") ? (
          <div>
            <br />
            <Message
              type="success"
              closable
              onClose={() => Cookies.remove("sale_uid")}
            >
              Click{" "}
              <a
                href={`https://track.salesmart.in/?${Cookies.get("sale_uid")}`}
              >
                here
              </a>{" "}
              to track your order
            </Message>
            <br />
          </div>
        ) : (
          ""
        )}
        {Cookies.get("order_placed") ? (
          <div>
            <br />
            <Message type="success" showIcon>
              Your order was placed successfully, thank you!
              <br />
            </Message>
            <br />
          </div>
        ) : (
          ""
        )}

        <span className="simple-text-banner">
          <h5>Discover Premium Lighting Solutions</h5>
          <i>Direct from Manufacturers</i>
        </span>

        {selected ? (
          <main>
            <hr />
            <Breadcrumb>
              <Breadcrumb.Item onClick={() => this.productViewer(false)}>
                <Button appearance="link" size="sm">
                  <ArowBackIcon /> &nbsp;Home
                </Button>
              </Breadcrumb.Item>
              <Breadcrumb.Item active>{selected.category_name}</Breadcrumb.Item>
            </Breadcrumb>
            <BigProduct
              product={selected}
              addProduct={this.addProduct}
              removeProduct={this.removeProduct}
              isInCart={this.isInCart}
              big={true}
              miniLoading={this.state.miniLoading}
              gotoCart={() => this.gotoCart(false)}
            />
            <br />
            <Divider>More from {selected.category_name}</Divider>
            {products
              .filter(
                (i) =>
                  i.category_id == selected.category_id && i.id != selected.id
              )
              .map((product) => (
                <Product
                  key={product.id}
                  product={product}
                  addProduct={this.addProduct}
                  removeProduct={this.removeProduct}
                  isInCart={this.isInCart}
                  click={() => {
                    this.productViewer(true, product);
                    this.updateProductUrl(product);
                  }}
                  miniLoading={this.state.miniLoading}
                />
              ))}
          </main>
        ) : (
          <main className="main-content">
            {/* Render each product using the Product component */}
            {this.state.loading ? (
              <div className="loading">
                <Loader size="md" content="loading.." />
              </div>
            ) : (
              <div>
                {products.map((product) => (
                  <Product
                    key={product.id}
                    product={product}
                    addProduct={this.addProduct}
                    removeProduct={this.removeProduct}
                    isInCart={this.isInCart}
                    click={() => {
                      this.productViewer(true, product);
                      this.updateProductUrl(product);
                    }}
                    miniLoading={this.state.miniLoading}
                  />
                ))}
              </div>
            )}
          </main>
        )}

        <Footer />
      </div>
    );
  }
}

export default StoreHome;
